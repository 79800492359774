.root {
}

.inputError {
  border: var(--borderErrorField);
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.subscriptionTitle {
  font-weight: bold;
  color: black;
  font-size: 22px;
}

.subscriptionAltTitle {
  color: black;
  font-size: 16px;
}

.subscriptionTitleFooter {
  color: white !important;
}

.subscriptionSubtitle {
  font-weight: normal;
  font-size: 10px;
}

.subscriptionFormContainer {
  display: flex;

  & > div {
    width: 70%;
  }

  & button {
    width: 30%;
  }

  & input {
    height: 100%;
  }
}

.subscriptionButton {
  background-color: #FC5F1D;
}

.subscriptionButtonFooter {
  background-color: var(--colorBlack);
}
