@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
}

.reviewItem {
  margin-bottom: 32px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (--viewportMedium) {
    margin-bottom: 39px;
  }
}

.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.reviewFromStore {
  display: flex;
  flex-direction: column;
}

.storeReviewer {
  display: flex;
}

.avatar {
  flex-shrink: 0;
  margin-right: 22px;
}

.reviewContent {
  composes: textSmall from global;
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.storeReviewContent {
  composes: textXSmall from global;
  margin: 0;
  white-space: pre-line;
}

.reviewInfo {
  composes: h5 from global;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--colorGrey300);

  @media (--viewportMedium) {
    margin-top: 9px;
  }
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.mobileReviewRating {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px;

  @media (--viewportMedium) {
    display: none;
  }
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: none;

  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;

  &:last-of-type {
    margin-right: 0;
  }
}

.fromStoreContainer {
  display: grid;
  gap: 1.5em;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.fromStore {
  background: white;
  border: 0;
  border-radius: 4px;
  height: 100%;
  box-shadow: 1px 1px 5px 1px #a7a7a7;
  padding: 10px;
}
